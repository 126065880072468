<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Richiesta ferie e permessi</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          
        </ion-toolbar>
      </ion-header>
      
      <div id="container">
        
        <ion-item>
            <ion-label>Seleziona la richiesta:</ion-label>                      
            <ion-select id="selezione" v-model='holiday.type'>
              <ion-select-option id="selezione" value="ferie">Ferie</ion-select-option>
              <ion-select-option  id="selezione" value="permesso">ROL</ion-select-option>
              <ion-select-option id="selezione" value="malattia">Malattia</ion-select-option>
              <ion-select-option  id="selezione" value="lutto">Lutto</ion-select-option>
              <ion-select-option id="selezione" value="infortunio">Infortunio</ion-select-option>
              <ion-select-option  id="selezione" value="congedo parentale">Congedo parentale</ion-select-option>
              <ion-select-option id="selezione" value="cassa integrazione">Cassa integrazione</ion-select-option>
              <ion-select-option id="selezione" value="smart working">Smart Working</ion-select-option>
            </ion-select>
        </ion-item>
        <br>
        <ion-item v-if="holiday.type !=''">
           <ion-label>Seleziona il formato della richiesta:</ion-label>        
            <ion-select id="selformato" v-model="holiday.format">
              <ion-select-option id="selezione" value="giornate">Giornata</ion-select-option>
              <ion-select-option  id="selezione" value="ore">Ore</ion-select-option>
            </ion-select>
        </ion-item>
        <div id="ferie" v-if="holiday.format == 'giornate' && holiday.type !=''">
          <ion-item id="insdatainizio">
            <ion-label>Da:</ion-label>
            <ion-datetime :min="minDate" :max="maxDate" display-format="DD-MM-YYYY" date-format="DD-MM-YYYY" id="inizioferie" v-model="holiday.begindate"></ion-datetime>
          </ion-item>
          <ion-item id="insdatafine">
            <ion-label>A:</ion-label>
            <ion-datetime :min="minDate" :max="maxDate" display-format="DD-MM-YYYY" id="fineferie" v-model="holiday.enddate"></ion-datetime>
          </ion-item>
          <ion-item id="insnote">
            <ion-label>Note:</ion-label>
            <ion-textarea id="note" v-model="holiday.note"></ion-textarea>
          </ion-item>
        </div>
        <div id="permesso" v-if="holiday.format == 'ore'">
          <ion-item id="insdata">
            <ion-label>Data:</ion-label>
            <ion-datetime :min="minDate" :max="maxDate" display-format="DD-MM-YYYY" id="datapermesso" v-model="holiday.begindate"></ion-datetime>
          </ion-item>
          <ion-item id="insorainizio">
            <ion-label>Dalle:</ion-label>
            <ion-datetime :min="minDate" :max="maxDate" display-format="HH:mm" id="iniziopermesso" v-model="holiday.begintime"></ion-datetime>
          </ion-item>
          <ion-item id="insorafine">
            <ion-label>Alle:</ion-label>
            <ion-datetime display-format="HH:mm" id="finepermesso" v-model="holiday.endtime"></ion-datetime>
          </ion-item>
          <ion-item id="insnote">
            <ion-label>Note:</ion-label>
            <ion-textarea id="note" v-model="holiday.note"></ion-textarea>
          </ion-item>
        </div>
        <br>
        <ion-button  v-if="holiday.type !='' && holiday.format=='ore' && holiday.begindate!='' && holiday.begintime!='' && holiday.endtime!=''" type="submit" @click="sendRichiesta">
          Invia richiesta 
        </ion-button>
        <ion-button  v-if="holiday.type !='' && holiday.format=='giornate' && holiday.begindate!='' && holiday.enddate!=''" type="submit" @click="sendRichiesta">
          Invia richiesta
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
      IonButtons, 
      IonButton, 
      IonContent, 
      IonDatetime, 
      IonHeader, 
      IonItem, 
      IonLabel, 
      IonMenuButton, 
      IonPage, 
      IonTextarea,
      IonTitle, 
      IonToolbar, 
      IonSelect, 
      IonSelectOption, 
      toastController 
    } from '@ionic/vue';

    export default {
      name: 'Folder',
      components: {
        IonButtons,
        IonButton,
        IonContent,
        IonDatetime,
        IonHeader,
        IonItem,
        IonLabel,
        IonMenuButton,
        IonPage,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        IonTitle,
        IonToolbar,
      },
    data: function(){
      return {
        type:'',
        holiday:{
          id:0,
          type:'',
          begindate:'',
          enddate:'',
          begintime:'',
          endtime:'',
          note:'',
          format:'',
        },
        user:{
        name:'',
        email:'',
        password:'',
        minDate:'',
        maxDate:'',
      },
    }
  },
  methods:{
   async sendRichiesta(ev) {
    ev.preventDefault();
    if(this.holiday.type=="permesso"){
        this.axios.post(this.apiUrl + 'save-holiday', {holiday:this.holiday}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.holiday.id='';
            this.holiday.type='';
            this.holiday.begindate='';
            this.holiday.enddate='';
            this.holiday.begintime='';
            this.holiday.endtime='';
            this.holiday.note='';
            this.holiday.format='';
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/Dashboard');
            return toast.present();
            }
        })
      }else{
        this.axios.post(this.apiUrl + 'save-holiday', {holiday:this.holiday}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.holiday.id='';
            this.holiday.type='';
            this.holiday.begindate='';
            this.holiday.enddate='';
            this.holiday.begintime='';
            this.holiday.endtime='';
            this.holiday.note='';
            this.holiday.format='';
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/report-permessi');
            return toast.present();
          }
        })
      }
    },
    getUserInfo(){
      this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
      .then(async (response) => {
        this.user=response.data.user;
        this.holiday.id=this.user.id;
        this.minDate=response.data.now;
        this.maxDate=response.data.fiveYear;
      })
    },
    ionViewWillEnter: function(){
      this.getUserInfo();
    }
  }
}
</script>

<style scoped>
  @media only screen and (max-width: 400px) {
    #container{
      width:100%;
      min-height:60%;
      display:flex;
      align-items:center;
      justify-content:center;
      display:block;
      flex-wrap:wrap;
    }
    ion-button{
      align-items:center;
    }
  }
  ion-button{
    text-align:center;
  }
  #container{
    min-width:95%;
  }
</style>